.Button{
  cursor: pointer;
  display: inline-block;
  background: var(--dark);
  padding: 8px 18px;
  border-radius: 8px;
  font-size: 12px;
  mix-blend-mode: multiply;
}

span{
  color: white;
  font-weight: 400;
  text-transform: uppercase;
 
}