.Statistic{
  &__label{
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 5px;
  }

  &__2col{
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__balance{
 font-size: 37px;
  }
}