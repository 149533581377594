.MenuItem {
  
  cursor: pointer;
  position: relative;


  &.active {
    i {
      color: var(--white);
    }
  }

  i {
    font-size: 24px;
  }

  &__dot {
    --size: 9px;
    width: var(--size);
    height: var(--size);
    border-radius: var(--size);
    background: #614AD3;
    filter: blur(1.5px);
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
  }
}