.IconButton{
  --size: 45px;
  background: var(--gradient);
  width: var(--size);
  height: var(--size);
  border-radius: var(--size);
  display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
}

i{
  font-size: 24px;
}

