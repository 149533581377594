.Block {
  background: var(--dark);
  padding: 12px;
  border-radius: 20px;
  display: flex;
  gap: 10px;
  align-items: center;

  &.big {
    justify-content: space-between;
    padding: 23px 36px;
  }
}


