.CircleStatistic {
  &__circle {
    --size: 135px;
    width: var(--size);
    height: var(--size);
    position: relative;
    margin-bottom: 20px;
    position: relative;
  }

  svg {
    fill: transparent;
    circle {
      transform: rotate(-90deg);
      transform-origin: center;
      stroke-width: 6px;
      transition: 0.5s linear;
      stroke-linecap: round;
      &:first-of-type {
        stroke: var(--grey);
        opacity: 0.2;
      }
    }
    .CircleStatistic__circle-disabled {
      stroke: url(#grey);
    }
    .CircleStatistic__circle-primary {
      stroke: url(#primary);
    }
  }


  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    font-weight: 500;
    &.disabled {
      opacity: 0.5;
    }
  }
  &__label {
    text-align: center;
    text-transform: uppercase;
    font-size: 11px;
  }
}